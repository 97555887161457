<template>
  <b-row v-if="userData">
    <b-col lg="12" sm="12">
      <b-card class="mb-0">
        <!-- <b-card-header>
        <h4>Employee</h4>
      </b-card-header> -->
        <b-row class="m-2">
          <b-col cols="12" md="3">
            <div class="d-flex flex-column">
              <b-avatar
                :src="userData.avatar"
                rounded="bottom"
                variant="light-dark"
                size="138"
              />
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <table class="mt-2 mt-xl-0 w-100">
              <!-- <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">User ID</span>
            </th>
            <td class="pb-50">
              {{ userData._id }}
            </td>
          </tr> -->
              <tr>
                <th class="pb-50">
                  <feather-icon icon="UserIcon" class="mr-75" />
                  <span class="font-weight-bold">{{ $t("full_name") }}</span>
                </th>
                <td class="pb-50">
                  {{ userData.lastName }}
                  {{ userData.firstName }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="StarIcon" class="mr-75" />
                  <span class="font-weight-bold">{{ $t("department") }}</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.department ? userData.department.name : "-" }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CreditCardIcon" class="mr-75" />
                  <span class="font-weight-bold">{{ $t("national_id") }}</span>
                </th>
                <td class="pb-50">
                  {{ userData.nationalID }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CheckIcon" class="mr-75" />
                  <span class="font-weight-bold">{{ $t("status") }}</span>
                </th>
                <td class="pb-50 text-capitalize">
                  <span
                    class="bullet bullet-sm"
                    :class="`bullet-${mappingStatusColor(userData.status)}`"
                  />
                  <span>
                    {{ mappingStatusText(userData.status) }}
                  </span>
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col lg="12" sm="12" class="mt-2">
      <b-card :title="$t('evaluation')">
        <b-form>
          <!-- Category -->
          <b-form-group :label="$t('category.default')" label-for="category">
            <v-select
              @input="onChangeCategory"
              v-model="evaluationEmployee.category"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categoryOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="category"
            />
          </b-form-group>

          <b-form-group v-if="subCategoryOptions.length > 0">
            <b-form-checkbox
              v-for="option in subCategoryOptions"
              :key="option.value"
              v-model="evaluationEmployee.reason"
              :value="option.name"
              name="flavour-3a"
              class="mb-1"
            >
              {{ option.name }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group :label="$t('detail')" label-for="detail">
            <b-form-textarea
              id="detail"
              v-model="evaluationEmployee.detail"
              trim
            />
          </b-form-group>

          <label for="attach-image">แนบหลักฐาน (ขนาดไฟล์ 500 kb)</label>
          <b-row v-for="(image, index) in previewImages" :key="index">
            <b-col lg="12">
              <div class="d-flex mb-1">
                <a :href="image" target="_blank">
                  {{ image.split("/")[3] }}
                </a>
                <div class="delete">
                  <feather-icon
                    icon="XIcon"
                    size="14"
                    @click="onDeleteImage(index)"
                  ></feather-icon>
                </div>
              </div>
            </b-col>
            <!-- <b-col
              sm="6"
              lg="2"
              v-for="(image, index) in previewImages"
              :key="index"
            >
              <div class="attach-image-box">
                <div class="delete">
                  <feather-icon
                    icon="XIcon"
                    size="20"
                    @click="onDeleteImage(index)"
                  ></feather-icon>
                </div>
                <img :src="image" />
              </div>
            </b-col> -->
          </b-row>
          <b-row>
            <b-col lg="2">
              <div class="upload-image-box" @click="$refs.refInputEl.click()">
                <feather-icon icon="UploadCloudIcon" size="28"></feather-icon>
                <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @click="$event.target.value = ''"
                  @input="onSelectImage"
                />
                <p style="margin-top: 5px" class="mb-0">
                  {{ $t("attachment") }}
                </p>
              </div>
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" @click="evaluateEmployee">
              {{ $t("report") }}
            </b-button>
            <b-button type="button" variant="outline-secondary">
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormGroup,
  BFormTextarea,
  BAvatar,
  BCardHeader,
  BFormCheckboxGroup,
  BFormCheckbox,
  BForm,
  BFormFile,
} from "bootstrap-vue";
import router from "@/router";
import vSelect from "vue-select";
import { onMounted, ref, watch } from "@vue/composition-api";
import { mappingStatusColor, mappingStatusText } from "@/libs/helper";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { employeeService } from "@/services/employee";
import { reasonEvaluation } from "@/services/reason_evaluation";
import { uploadService } from "@/services/upload";
import { evaluationService } from "@/services/evaluation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import Swal from "sweetalert2";
export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    BFormCheckboxGroup,
    BFormCheckbox,
    BAvatar,
    BForm,
    BFormFile,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  setup() {
    const fileList = ref([]);
    const previewImages = ref([]);
    const toast = useToast();
    const onSelectImage = (e) => {
      const file = e.target.files[0];
      fileList.value.push(file);
      previewImages.value.push(URL.createObjectURL(file));
    };

    const onDeleteImage = (index) => {
      fileList.value.splice(index, 1);
      previewImages.value.splice(index, 1);
    };

    const categoryOptions = ref([]);
    const subCategoryOptions = ref([]);
    const evaluationEmployee = ref({
      reason: [],
      detail: "",
      attachment: [],
      category: "",
    });

    const onChangeCategory = (id) => {
      subCategoryOptions.value = categoryOptions.value.find(
        (cate) => cate.value === id
      ).subCategory;

      evaluationEmployee.value.reason = [];
    };

    const selected = ref([]);
    const userData = ref(null);
    const id = router.currentRoute.params.id;

    onMounted(() => {
      getAllReason();
      getEmployee();
    });

    const getAllReason = async () => {
      try {
        const result = await reasonEvaluation.getAllReason();

        if (result.status) {
          evaluationEmployee.value.category = result.data[0]._id;

          categoryOptions.value = result.data.map((item) => ({
            label: item.category,
            value: item._id,
            subCategory: item.subCategory,
          }));
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getEmployee = () => {
      employeeService
        .getData(id)
        .then((response) => {
          if (response.status) {
            userData.value = response.data;
            const imageURL = `${process.env.VUE_APP_API_URL}/files/${response.data.avatar}`;
            userData.value.avatar = imageURL;
          }
        })
        .catch((error) => {
          userData.value = undefined;
        });
    };

    const evaluateEmployee = async () => {
      try {
        const evaID = router.currentRoute.query.eva;
        for (let i = 0; i < fileList.value.length; i++) {
          const formData = new FormData();
          formData.append("file", fileList.value[i], fileList.value[i].name);

          formData.append("fileType", "attachment");
          const respCommercialFile = await uploadService.uploadFile(formData);
          evaluationEmployee.value.attachment.push(respCommercialFile.data._id);
        }

        const result = await evaluationService.reportEmployee(evaID, {
          abnormalInformation: evaluationEmployee.value,
        });

        if (result.status) {
          Swal.fire({
            icon: "success",
            title: "ส่งรายงานประเมินเรียบร้อย!",
            showConfirmButton: false,
            timer: 1500,
          });
          //Router push to company list
          setTimeout(() => {
            router.push({ name: "evaluation" }).catch(() => {});
          }, 1600);
        }
      } catch (e) {
        console.log(e);
      }
    };
    return {
      userData,
      selected,

      previewImages,
      onSelectImage,
      onDeleteImage,
      categoryOptions,
      evaluationEmployee,
      mappingStatusColor,
      mappingStatusText,
      onChangeCategory,
      subCategoryOptions,
      evaluateEmployee,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.upload-image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid #b3b3b3;
  border-radius: 5px;
  border-style: dotted;
  height: 130px;
  cursor: pointer;
}
.attach-image-box {
  position: relative;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  height: 130px;
}
.attach-image-box .delete {
  position: absolute;
  top: 6px;
  left: 87%;
  background-color: #eeebeb;
  border-radius: 10000px;
  padding: 2px;
  cursor: pointer;
}

.delete {
  width: 20px;
  height: 20px;
  background-color: #eeebeb;
  border-radius: 10000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  cursor: pointer;
}

.attach-image-box img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
